<route>
{
"meta": {
"title": "Privacy & Terms",
"auth": false
}
}
</route>

<template>
  <div>
    <header>
      <h1>Terms &amp; Privacy</h1>
    </header>
    <div>
      <p><strong>PRIVACY AND PROTECTION OF PERSONAL INFORMATION&nbsp;</strong></p>
      <p><strong>Regarding collection of your Personal Information:</strong></p>
      <p>We will ask you when we need information that personally identifies you&nbsp;(personal information) or allows
        us to contact you. Generally, this information is requested when you are signing up to ERA membership, becoming
        a director or registering for our current or future services.</p>
      <p>Personal information collected by ERA is usually Corporate related data but may include other information when
        needed&nbsp;to provide a service if required.</p>
      <p>ERA also collects information about which pages our members and visitors visit within our site. See google
        analytics below</p>
      <p><strong>Regarding use of your Personal Information:</strong></p>
      <p>We use your personal information for the following purposes:</p>
      <ul>
        <li>To ensure our site and services are relevant to your needs.</li>
        <li>To deliver services, such as news, documents, training, insight events, surveys and other information, that
          are part of your membership or that you have purchased.
        </li>
        <li>To help us create and publish content most relevant to you.</li>
        <li>To alert you to new services, updated information and other membership information.</li>
        <li>To allow you access to limited-entry areas of our site as appropriate</li>
      </ul>
      <p>We occasionally hire other companies to provide limited services on our behalf, including specific member
        services that require specialist company involvement or answering customer questions about products or services.
        We will only provide those companies the information they need to deliver the services to enhance your member
        service and they are prohibited from using that information for any other purpose. When sharing information we
        will comply with all aspects of data protection law. All our&nbsp;data sharing&nbsp;relationships are governed
        by terms which include strict data sharing and confidentiality protocols.&nbsp;</p>
      <p><strong><em>We never share personal information with other organisations for third-party marketing
        purposes.</em></strong></p>
      <p>However, where &lsquo;sharing&rsquo; is in our &lsquo;legitimate business interests&rsquo;, we may share your
        information without seeking your consent first.&nbsp;</p>
      <p>We may also share information when required by law for example where ordered by the Court or to protect an
        individual from immediate harm.</p>
      <p>Your information may be stored and processed in the United Kingdom or any other country in which ERA or its
        affiliates, subsidiaries or agents maintain facilities, and by using this site, you consent to any such transfer
        of information outside of your country. ERA abides by the EU General Data Protection Regulation regarding the
        collection, use, and retention of data.&nbsp;<br/><br/></p>
      <p><strong>Regarding control of your Personal Information:</strong></p>
      <p>When you register, sign up to membership or otherwise give us personal information, ERA&nbsp;will not share
        that information with third parties without your permission, other than for the limited exceptions already
        listed. It will only be used for the purposes stated above.&nbsp;</p>
      <p>ERA may send out periodic e-mails informing you of new services or information related to your membership. You
        will not be able to choose to unsubscribe to these mailings, as they are considered an essential part of the
        service.&nbsp;</p>
      <p><strong>Regarding access to your Personal Information:</strong></p>
      <p>We will annually as part of the membership renewal process provide you with the means to ensure that your
        personal information is correct and current. You may also contact us at any time to review or update this
        information in the interim&nbsp;</p>
      <p><strong>Regarding security of your Personal Information:</strong></p>
      <p>ERA is committed to protecting the security of your personal information. We use a variety of security
        technologies and procedures to help protect your personal information from unauthorised access, use or
        disclosure.</p>
      <p>We will apply appropriate technical and organisational measures to ensure your personal information is secure.
        For example, we have systems in place to ensure that access to personal information is restricted to authorised
        individuals on a strictly need-to-know basis.</p>
      <p>When we need to share personal data with our contractors and third party suppliers, our relationships are
        governed by our contracts with them which include strict data sharing and confidentiality protocols.&nbsp;</p>
      <p><strong>Your Rights</strong></p>
      <p>We are committed to upholding your rights in respect of your personal data.</p>
      <p><strong>The right to be informed</strong></p>
      <p>Through the provision of this and other privacy notices on the website, we will be open and transparent about
        how and why we use your personal information.</p>
      <p><strong>The right of access</strong></p>
      <p>You have a right to ask us what personal information we hold about you and to request a copy of your
        information. This is known as a &lsquo;subject access request&rsquo; (SAR).</p>
      <p>SARs need to be made in writing and we ask that your written request is accompanied by proof of your address
        and identity.</p>
      <p>If you are seeking to obtain specific information (e.g. about a particular matter or from a particular time
        period), it helps if you clarify the details of what you would like to receive in your written request.</p>
      <p>If someone is requesting information on your behalf they will need written confirmation from you to evidence
        your consent for us to release this and proof of ID (both yours and theirs).</p>
      <p>We have 30 calendar days within which to provide you with the information you&rsquo;ve asked for (although we
        will try to provide this to you as promptly as possible).</p>
      <p><strong>The right to rectification</strong></p>
      <p>You can ask us to rectify your personal data if it is inaccurate or incomplete. Please help us to keep our
        records accurate by keeping us informed if your details change.</p>
      <p><strong>The right to erasure</strong></p>
      <p>The right to erasure is also known as &lsquo;the right to be forgotten&rsquo;. In some circumstances, you can
        ask us to delete or remove personal data where&nbsp;there is no compelling reason for its continued processing.
        This is not an absolute right, and we will need to consider the circumstances of any such request and balance
        this against our need to continue processing the data. Our response will also be guided by the provisions of our
        retention schedule.</p>
      <p><strong>The right to object</strong></p>
      <p>You can tell us you object to us processing your data to provide news update emails.</p>
      <p><strong>How long will you keep my data</strong></p>
      <p>We only hold records during the period of our relationship and for a set period afterwards to allow us to meet
        our legal obligations, including resolving any follow up issues between us.</p>
      <p>Please contact us if you would like any more information.&nbsp;</p>
      <p><strong>&nbsp;Regarding enforcement of this Privacy Statement:</strong></p>
      <p>If you have questions regarding this statement, you should contact ERA by e-mail: admin@eraltd.org</p>
      <p><strong>Regarding changes to this Statement:</strong></p>
      <p>ERA may, from time to time, update this privacy statement.&nbsp;</p>
      <p><strong>Cookies</strong></p>
      <p>To make this site simpler to use, we sometimes place small files on your computer. These are known as cookies,
        almost all websites do this too. Cookies have many uses and most are not damaging to your privacy, all of the
        cookies used on this web site fall into that category. We do not use cookies that collect or transfer your
        personal data to other web sites for advertising or any other purpose.</p>
      <p>Cookies are used on this site purely to improve services for you through, for example:</p>
      <ul>
        <li>enabling a service to recognise your device so you don't have to give the same information several times
          during one task
        </li>
        <li>recognising that you may already have given a username and password so you don't need to do it for every web
          page requested
        </li>
        <li>measuring how many people are using the site, so it can be made easier to use and there's enough capacity to
          ensure it is fast enough
        </li>
      </ul>
      <p>We use Google Analytics to measure how many people use this site. We do this to make sure the site is meeting
        users' needs and to understand how we could improve it.</p>
      <p>Google Analytics stores information about what pages you visit, how long you are on the site, how you got here
        and what you click on. We do not collect or store any personal information (e.g. your name or address) so this
        information cannot be used to identify who you are. We also do not allow Google to use or share our analytics
        data.</p>
      <p>The following cookies are used by Google Analytics:</p>
      <ul>
        <li>_utma stores a randomly generated number for 2 years</li>
        <li>_utmb stores a randomly generated number for 30 minutes</li>
        <li>_utmc stores a randomly generated number until you close your browser</li>
        <li>_utmz stores a randomly generated number and anonymous information on how the site was reached (e.g. direct
          or via a link or a search) for 6 months
        </li>
      </ul>
      <p>The following cookie is used to provide other normal website functions:</p>
      <ul>
        <li>net_sessionid stores a randomly generated number until you close your browser</li>
      </ul>
      <p>If you do not wish to accept any cookies on to your machine you can disable them by adjusting the settings on
        your browser. However this is likely to adversely affect the functionality of websites you visit.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'privacy'
}
</script>

<style scoped>

</style>
